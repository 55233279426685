
import Vue from 'vue';

export default Vue.extend({
	name: 'PageNotFound',

	data() {
		return {};
	},

	fetch({ error }) {
		error({ statusCode: 404, message: 'Page not found' });
	},
	head() {
		return {
			title: 'Ups',
			meta: [
				{
					hid: 'robots',
					name: 'robots',
					content: 'noindex, nofollow'
				}
			]
		};
	}
});
